.container{
    align-self: center;
    width: 650px;
    height: 250px;
}

.image{
    margin-left: 150px;
    width: 500px;
    height: 250px;
    background-color: white;
    background-size: cover;
    position: absolute;
    background-position: center;
}

.svg{
    position: absolute;
}

.content{
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    position: absolute;
    aspect-ratio: 6/9;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 170px;
}

.titulo, .descripcion{
    font-family: pluto;
    color: white;
    margin: 0;
}

.titulo{
    font-size: 1.2rem !important;
    margin-bottom: 20px;
}

.descripcion{
    font-size: .8rem !important;
    white-space: pre-line;
}

.precio{
    font-size: 1.2rem  !important;
    font-weight: bold;
    margin-top: 20px;
    color: white;
    margin: 0;
}