.ReactModal__Overlay {
    opacity: 0;
    transition: all 300ms ease-in-out;
    margin-top: -25vh;
    height: 150vh;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Body--open {
    overflow-y: hidden;
}

.navbar{
    margin: 0;
}

.header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
}

.closeIcon{
    position: absolute;
    left: 5%;
}

.logoiso{
    width: 100px;
}

.navbarBtns{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.btnContainer{
    width: 100%;
    border-bottom: 3px solid white;
    padding: 5%;
    padding-left: 8%;
}

.btn{
    font-family: pluto;
    color: white;
    font-size: 1.2rem;
    text-decoration: none;
}

a{
    text-decoration: none;
}

.promosContainer{
    width: 330px;
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.closeContainer{
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.promosClose,
.contactoClose{
    cursor: pointer;
}

.promosContainer p,
.contactoContainer p{
    font-family: pluto;
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    text-align: center;
    margin: 0;
}

.contactoContainer{
    width: 90vw;
    max-width: 700px;
    min-width: 320px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding-top: 20px;
}


.logoiso{
    width: 220px;
    position: absolute;
    margin-top: -390px;
    z-index: 1;
}

.redesContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}

.iconContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    aspect-ratio: 1;
    border: 3px solid white;
    border-radius: 50%;
    cursor: pointer;
}

@media (max-width: 800px) {
    .contactoContainer p{
        font-size: 1.3rem;
    }
}