.container{
    width: 100vw;
    height: 200px;
    background-color: #152F48;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
}

.direccionContainer{
    margin-left: 60px;
}

.horariosContainer{
    margin-right: 60px;
}

.logoiso{
    width: 230px;
}

.divider{
    width: 100%;
    height: 3px;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}

.direccion,
.horarios,
.horariosTitle{
    font-family: pluto;
    color: white;
    margin: 0;
    font-size: 1.2rem;
}

.horariosTitle{
    font-size: 1.5rem;
}

.contactoContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}

.iconContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    aspect-ratio: 1;
    border: 3px solid white;
    border-radius: 50%;
    cursor: pointer;
}

@media (max-width: 900px) {
    .direccionContainer,
    .contactoContainer,
    .horariosContainer{
        scale: .9
    }
}

@media (max-width: 750px) {
    
    .direccionContainer {
        display: none;
    }

    .horariosContainer{
        margin-right: 0px;
        text-align: center;
    }

    .container{
        flex-direction: column;
        gap: 1.5rem;
        padding-top: 30px;
        padding-bottom: 60px;
    }
}