.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;    
    width: 95%;
    max-width: 1300px;
    z-index: 1;
}

.swiper {
  width: 90%;
  max-width: 1200px;
  height: 400px;
  border-radius: .4rem;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.115), 0 5px 5px rgba(0, 0, 0, 0.132);
}

.swiperSlide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.prevIcon,
.nextIcon{
    height: 40px;
    width: 20px;
    cursor: pointer;
}

.nextIcon{
    transform: rotate(180deg);
}

@media (max-width: 850px) {

  .swiper{
    height: 15rem;
  }

  .nextIcon,
  .prevIcon{
    display: none;
  }

}

@media (max-width: 600px) {

    .container{
        width: 100vw;
    }

    .swiper{
        height: 12rem;
        width: 100vw;
    }

}

@media (max-width: 450px) {

    .container{
        width: 100vw;
    }

  .swiper{
    width: 100vw;
    height: 13rem;
  }

}