.container{
    width: 100%;
    height: 100%;
}

.image{
    margin-left: 20%;
    width: 80%;
    height: 100%;
    background-color: white;
    background-size: cover;
    position: absolute;
    background-position: center;
}

.svg{
    position: absolute;
}

.content{
    padding-left: 30px;
    padding-right: 30px;
    margin: 0;
    position: absolute;
    aspect-ratio: 6/9;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.titulo, .descripcion{
    font-family: pluto;
    color: white;
    margin: 0;
    white-space: pre-line;
}

.titulo{
    font-size: 1.8rem;
    margin-bottom: 20px;
}

.precio{
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 20px;
    color: white;
    margin: 0;
}


@media (max-width: 850px) {
    .titulo{
        font-size: 1.1rem;
        margin-bottom: 5px;
    }

    .descripcion{
        font-size: .8rem;
    }

    .precio{
        font-size: 1.4rem;
        margin-top: 5px;
    }
  
}
  
  
@media (max-width: 600px) {
    .titulo{
        font-size: 1rem;
        margin-bottom: 5px;
    }

    .descripcion{
        font-size: .7rem;
    }
    .precio{
        font-size: 1.2rem;
        margin-top: 5px;
    }

    .content{
        padding-left: 15px;
    }
}