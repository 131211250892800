.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: #E6E7E8;
    padding: 8px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 5px;
}

.color{
    width: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
}

.img{
    height: 60px;
    width: 100px;
    object-fit: cover;
    border-radius: 5px;
}

.titleContainer{
    width: 150px;
    text-align: center;
}

.title{
    line-height: 20px;
    font-family: Pluto;
}

.btn,
.btnBorrar,
.btnCancelar{
    background-color: #CC4747;
    color: white;
    font-family: Pluto;
    border: none;
    padding: 8px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
}

.arrowsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
}

.modalContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 300px;
    height: fit-content;
    padding: 2rem
}

.modalTitle{
    margin: 0;
    font-family: Pluto;
    color: white;
}

.btnBorrar{
    width: 200px;
}

.btnCancelar{
    background-color: #46c261;
    width: 200px;
}