.container{
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    width: fit-content;
    min-height: 100vh;
    height: fit-content;
    background-color: #F5F0EE;
}

.navbar{
    width: 100%;
    height: 100px;
    background-color: #F8DED2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.navbarLogo{
    height: 70%;
}

.navbarTitulo{
    color: #EA5B0C;
    font-family: Pluto;
    font-size: 2rem;
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.crearSlideContainer{
    margin-top: 2rem;
    border-radius: .5rem;
    margin-bottom: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    width: 700px;
    height: fit-content;
}

.moduleTitle{
    font-size: 1.5rem;
    margin: 0;
}

.inputTitulo,
.inputTexto{
    background-color: #E6E7E8;
    padding: 10px;
    border-radius: 5px;
    border: none;
    font-family: Pluto;
    outline: none;
    resize: none;
}

.inputTexto{
    white-space: pre-wrap;
}

.bottomDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.btnSubmitSlide,
.moduleBtn{
    background-color: white;
    font-family: Pluto;
    border: 2px solid black;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color .5s, color .5s;
}

.btnSubmitSlide{
    width: 200px;
}

.btnSubmitSlide:hover, .moduleBtn:hover{
    background-color: #F8DED2;
}

.horariosContainer{
    border-radius: .5rem;
    margin-top: 2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: .5rem;
    width: 700px;
    height: fit-content;

}

.horario{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.horarioText{
    font-family: Pluto;
}

.horarioInput{
    height: 30px;
    font-size: 1rem;
}

.slidesManagerContainer{
    margin-top: 2rem;
    border-radius: .5rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: .5rem;
    width: 700px;
    height: fit-content;
}

.slidesManagerHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.menuContainer{
    border-radius: .5rem;
    margin-top: 2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: .5rem;
    width: fit-content;
    height: fit-content;
    margin-bottom: 2rem;
    width: 700px;
}