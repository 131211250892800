.wrapper{
    position: relative;
    height: 100vh;
    width: 100vw;
}

.leaves{
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1;
}

.leaves i {
    margin-left: 20%;
    display: inline-block;
    width: 200px;
    height: 200px;
    z-index: 100;
    animation: falling 10s 0s infinite;
}

.hoja{
    height: 100%;
    width: 100%;
    z-index: 100;
    position: absolute;
}

.leaves i:nth-of-type(2n) { animation: falling2 10s 0s infinite; }
.leaves i:nth-of-type(3n) { animation: falling3 10s 0s infinite; }
.leaves i:nth-of-type(n)    { height:23px; width:23px; }
.leaves i:nth-of-type(2n+1)    { height:11px; width:11px; }
.leaves i:nth-of-type(3n+2)  { height:17px; width:17px; }

.leaves i:nth-of-type(n)   {animation-delay: calc(1.9s*2);}
.leaves i:nth-of-type(2n)  {animation-delay: calc(3.9s*2);}
.leaves i:nth-of-type(3n)  {animation-delay: calc(2.3s*2);}
.leaves i:nth-of-type(4n)  {animation-delay: calc(4.4s*2);}
.leaves i:nth-of-type(5n)  {animation-delay: calc(5s*2);  }
.leaves i:nth-of-type(6n)  {animation-delay: calc(3.5s*2);}
.leaves i:nth-of-type(7n)  {animation-delay: calc(2.8s*2);}
.leaves i:nth-of-type(8n)  {animation-delay: calc(1.5s*2);}
.leaves i:nth-of-type(9n)  {animation-delay: calc(3.3s*2);}
.leaves i:nth-of-type(10n) {animation-delay: calc(2.5s*2);}
.leaves i:nth-of-type(11n) {animation-delay: calc(1.2s*2);}
.leaves i:nth-of-type(12n) {animation-delay: calc(4.1s*2);}
.leaves i:nth-of-type(13n) {animation-delay: calc(1s*2);  }
.leaves i:nth-of-type(14n) {animation-delay: calc(4.7s*2);}
.leaves i:nth-of-type(15n) {animation-delay: calc(3s*2);  }

.leaves i:nth-of-type(n)    { opacity: 1;}
.leaves i:nth-of-type(3n+1)  { opacity: .8;}
.leaves i:nth-of-type(3n+2)  { opacity: .7;}

.leaves i:nth-of-type(n)    {transform: rotate(180deg);}


.leaves i:nth-of-type(n) {animation-timing-function:ease-in-out;}

@keyframes falling {
    
    0% {
        -webkit-transform:
            translate3d(300,0,0)
            rotate(0deg);
    }
    
    100% {
        -webkit-transform:
            translate3d(0px,700px,0)
            rotate(90deg);
        opacity: 0;
    }
}

@keyframes falling3 {
     0% {
        -webkit-transform:
            translate3d(0,0,0)
            rotate(-20deg);
    }
       
    100% {
        -webkit-transform:
            translate3d(-130px,640px,0)
            rotate(-70deg);
        opacity: 0;
    }
}

@keyframes falling2 {
     0% {
        -webkit-transform:
            translate3d(0,0,0)
            rotate(90deg);
    }
    
    100% {
        -webkit-transform:
            translate3d(-200px,680px,0)
            rotate(0deg);
        opacity: 0;
    }
}

.container{
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #F8DED2;
    overflow: hidden;
    overflow-y: hidden;
    z-index: 1;
}

.sol{
    position: absolute;
    margin-top: 40px;
    margin-left: 540px;
    /* width: 1344px; */
    width: 70vmax;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: #EA5B0C;
    z-index: 0;
}

.circuloAzul{
    position: absolute;
    margin-top: 770px;
    margin-left: 230px;
    /* width: 380px; */
    width: 40vh;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: #152F48;
    z-index: 0;
}

.circuloAzulEje{
    position: absolute;
    margin-top: -250px;
    /* margin-left: 1110px; */
    margin-left: calc(100vw - 380px - 420px);
    width: 380px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: #152F48;
    z-index: 0;
}

.ondas{
    position: absolute;
    height: 150px;
    width: auto;
    margin-top: calc(100vh - 150px);
    /* margin-left: 1030px; */
    margin-left: calc(100vw - 900px);
    animation: stretch 5s infinite ease-in-out;
}

@keyframes stretch {
    0% {
      transform: skew(4deg);
    }
    50%{
        transform: skew(0deg)
    }
    100% {
        transform: skew(4deg);
    }
}

.barras{
    position: absolute;
    width: 335px;
    height: 80%;
    /* margin-left: 1500px; */
    margin-left: calc(100vw - 335px - 100px);

}

.circuloDorado{
    position: absolute;
    /* margin-top: 570px; */
    margin-top: calc(100vh - 570px - -160px);;

    /* margin-left: 1350px; */
    margin-left: calc(100vw - 940px - -370px);

    width: 940px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: #D3A251;
}

.MyClassEnterActive {
    opacity: 0;
    transform: translate(100px, 0px);
    transition: transform 1s;
}
  
.MyClassEnterDone {
    opacity: 1;
    transform: translate(0px, 0px);
    transition: transform 1s;
}

.flor{
    position: absolute;
    height: 340px;
    /* margin-left: 1285px; */
    margin-left: calc(100vw - 340px - 300px);
    /* margin-top: 580px; */
    margin-top: calc(100vh - 340px - 55px);
    animation: rotation 50s infinite linear;
}

@keyframes rotation {
    from {
      transform: rotate(359deg);
    }
    to {
      transform: rotate(0deg);
    }
}

.florAzul{
    position: absolute;
    height: 170px;
    margin-left: -110px;
    margin-top: 30px;
}

.nube1{
    position: absolute;
    height: auto;
    width: 126px;
    margin-left: -30px;
    margin-top: 770px;

    animation-name: nube;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.nube2{
    position: absolute;
    height: auto;
    width: 126px;
    margin-left: 520px;
    margin-top: 830px;
    animation-name: nube;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: .8s;
}

.nube3{
    position: absolute;
    height: auto;
    width: 126px;
    /* margin-left: 980px; */
    margin-left: calc(100vw - 126px - 820px);
    margin-top: 95px;
    animation-name: nube;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 1.8s;
}

.nube4{
    position: absolute;
    height: auto;
    width: 126px;
    /* margin-left: 1440px; */
    margin-left: calc(100vw - 126px - 330px);
    margin-top: 135px;
    animation-name: nube;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 1.2s;
}

@keyframes nube {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }   
}

.header{
    margin-left: 8%;
    margin-top: 270px;
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.isotipo{
    width: 200px;
    height: auto;
    object-fit: contain;
    margin-bottom: 30px;
}

.header p{
    font-family: 'pluto';
    color: #EA5B0C;
    margin: 0;
    font-size: 1.6rem;
}

.headerSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.headerIcon{
    width: 30px;
    height: auto;
    object-fit: contain;
}

/* 2540 x 1440 */
@media (min-width: 2100px) {  

    .header{
        scale: 1.6;
        margin-left: 28%;
        margin-top: 14%;
    }

    .circuloAzul{
        margin-top: 900px;
    }

    .nube1,
    .nube2,
    .nube3,
    .nube4{
        scale: 1.2;
    }

    .nube2{
        margin-left: 700px;
        margin-top: 1000px;
    }

    .ondas,
    .circuloDorado,
    .flor{
        scale: 1.2
    }

    .flor{
        margin-left: calc(100vw - 340px - 350px);
        margin-top: calc(100vh - 340px - 140px);
    }

    .ondas{
        height: 200px;
        margin-top: calc(100vh - 200px);
        /* margin-left: 1030px; */
        margin-left: calc(100vw - 1100px);
    }

    .sol{
        margin-top: 40px;
        margin-left: 650px;
    }

}

/* 3840 x 2100 */
@media (min-width: 3200px) {     
    .header{
        scale: 2.4;
        margin-left: 45%;
        margin-top: 16%;
    }

    .circuloAzul{
        margin-top: 1350px;
        margin-left: 500px;
    }

    .circuloAzulEje{
        margin-top: -400px;
        /* margin-left: 1110px; */
        width: 700px;
        margin-left: calc(100vw - 700px - 850px);
    }

    .circuloDorado,
    .flor{
        scale: 2
    }
    .circuloDorado{
        margin-left: calc(100vw - 940px - -170px);
    }

    .flor{
        margin-left: calc(100vw - 340px - 800px);
        margin-top: calc(100vh - 340px - 400px);
    }

    .ondas{
        height: 300px;
        margin-top: calc(100vh - 300px);
        /* margin-left: 1030px; */
        margin-left: calc(100vw - 1900px);
    }

    .barras{
        width: 600px;
        margin-left: calc(100vw - 600px - 200px);
    }

    .nube1,
    .nube2,
    .nube3,
    .nube4{
        scale: 1.8
    }

    .nube1{
        margin-left: 0px;
        margin-top: 1300px;
    }

    .nube2{
        margin-left: 1100px;
        margin-top: 1500px;
    }

    .nube3{
        margin-left: 1900px;
        margin-top: 300px;
    }

    .nube4{
        margin-left: 2900px;
        margin-top: 400px;
    }

    .sol{
        margin-top: 40px;
        margin-left: 1000px;
    }

    .florAzul{
        scale: 1.8;
        margin-top: 150px;
    }
}


/* 1536 x 864 y  */
@media (max-width: 1550px) {

    .circuloDorado,
    .flor{
        scale: .7
    }

    .sol{
        margin-top: 40px;
        margin-left: 440px;
        width: 1000px;
    }

    .circuloDorado{
        margin-left: calc(100vw - 940px - -300px);
    }

    .flor{
        margin-left: calc(100vw - 340px - 240px);
    }

    .ondas{
        height: 140px;
        width: auto;
        margin-top: calc(100vh - 140px);
        /* margin-left: 1030px; */
        margin-left: calc(100vw - 800px);
    }

    .barras{
        width: 250px;
        height: 100vh;
        /* margin-left: 1500px; */
        margin-left: calc(100vw - 250px - 100px);
    }

}

@media (max-width: 1390px) {
    .circuloDorado,
    .flor{
        scale: .6
    }

    .nube1,
    .nube2,
    .nube3,
    .nube4{
        scale: .8
    }

    .flor{
        margin-left: calc(100vw - 340px - 170px);
        /* margin-top: 580px; */
        margin-top: calc(100vh - 340px - 0px);
    }

    .ondas{
        height: 120px;
        width: auto;
        margin-top: calc(100vh - 120px);
        /* margin-left: 1030px; */
        margin-left: calc(100vw - 680px);
    }

    .barras{
        width: 230px;
        height: 100vh;
        /* margin-left: 1500px; */
        margin-left: calc(100vw - 230px - 90px);
    }

    .circuloAzul{
        margin-top:600px;
        margin-left: 200px;
        /* width: 380px; */
        width: 40vh;
    }

    .nube1{
        margin-left: -20px;
        margin-top: 550px;
    }

    .nube2{
        margin-left: 400px;
        margin-top: 600px;
    }

    .nube3{
        margin-left: 600px;
    }

    .nube4{
        margin-left: 1000px;
    }

    .header{
        margin-left: 7%;
        margin-top: 200px;
        display: flex;
        flex-direction: column;
        gap: .4rem;
    }
    
}

@media (max-width: 1000px) {

    .barras,
    .circuloAzulEje,
    .florAzul,
    .circuloDorado,
    .nube3,
    .nube4{
        display: none;
    }

    .sol{
        position: absolute;
        margin-top: 50vh;
        margin-left: 20vw;
        /* width: 1344px; */
        width: 700px;
    }

    .circuloAzul{
        position: absolute;
        margin-top: 75vh;
        margin-left: -20vw;
        /* width: 380px; */
        width: 40vh;
    }

    .flor{
        position: absolute;
        height: 400px;
        /* margin-left: 1285px; */
        margin-left: 30vw;
        /* margin-top: 580px; */
        margin-top: calc(100vh - 400px - 100px);
    }

    .ondas{
        position: absolute;
        height: 150px;
        width: 445px;
        margin-top: calc(100vh - 150px);
        /* margin-left: 1030px; */
        margin-left: calc(100vw - 330px);
    }

    .nube1,
    .nube2{
        position: absolute;
        height: auto;
        width: 150px;
        margin-left: -30px;
        margin-top: 770px;
    }

    .nube1{
        margin-left: 20px;
        margin-top: 55vh;
    }

    .nube2{
        margin-left: 70vw;
        margin-top: 30vh;
    }

}

@media (max-width: 600px) {

    .header{
        margin-left: 20px;
        margin-top: 100px;
        scale: .9;
    }

    .nube1{
        margin-top: 450px;
    }

    .nube2{
        margin-top: 300px;
    }

    .flor{
        height: 300px;
        /* margin-left: 1285px; */
        margin-left: 30vw;
        /* margin-top: 580px; */
        margin-top: calc(100vh - 300px - 100px);
    }

    .circuloAzul{
        position: absolute;
        margin-top: 75vh;
        margin-left: -200px;
        /* width: 380px; */
        width: 40vh;
    }

}

@media (max-width: 400px) {

    .nube1,
    .nube2{
        width: 100px;
    }

}

@media (max-width: 600px) and (max-height: 800px){

    .flor{
        height: 250px;
        margin-left: 40vw;
        /* margin-top: 580px; */
        margin-top: calc(100vh - 250px - 100px);
    }

    .ondas{
        height: 120px;
        width: 356px;
        margin-top: calc(100vh - 120px);
        /* margin-left: 1030px; */
        margin-left: calc(100vw - 300px);
    }

}

@media (max-width: 370px) and (max-height: 769px){
    .header{
        scale: .7;
        margin-left: -10px;
        margin-top: 80px;
    }
}

@media (max-height: 700px){
    .flor{
        display: none;
    }
}