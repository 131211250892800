.container{
    padding-top: 150px;
    padding-bottom: 150px;
    width: 100vw;
    height: fit-content;
    z-index: 9;
    background-color: #F5F0EE;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    justify-content: center;
    min-height: 60vh;
}

.barras1,
.barras2{
    position: absolute;
    height: 100%;
    width: 100px;
}

.barras1{
    margin-left: 30px;
}

.barras2{
    margin-left: calc(100% - 100px - 30px);
}

.iframe{
    z-index: 1;
    width: 90%;
    max-width: 1165px;
    aspect-ratio: 16/8;
    border: none;
    border-radius: .4rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.115), 0 5px 5px rgba(0, 0, 0, 0.132);
}

.promosTitle{
    font-family: 'pluto';
    color: #152F48;
    margin: 0;
    font-size: 1.8rem;
    text-align: center;
    z-index: 1;
    margin-left: 20px;
    margin-right: 20px;
}