
.container{
    position: fixed;
    width: 100vw;
    height: 100px;
    background-color: #F5F0EE;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    z-index: 10;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.115), 0 5px 5px rgba(0, 0, 0, 0.132);
}

.logoiso{
    height: 65px;
    width: auto;
}

.btn{
    font-size: 1.5rem;
    font-family: pluto;
    color: #152F48;
    cursor: pointer;
    text-decoration: none;
}

.icon{
    position: absolute;
    top: 25%;
    left: 20px;
}

/* 3840 x 2100 */
@media (min-width: 3200px) { 

    .container{
        scale: 2;
        margin-top: 50px;
    }

}

@media (max-width: 445px) {
    .logoiso{
        height: 40px;
        width: auto;
        margin-left: 55px;
    }
}

